@layer packages {
    .pillBaseStyle {
        display: inline-flex;
        align-items: center;
        border-radius: calc(var(--globals-baseUnit) * 2);
        padding: 0.5rem 0.9rem 0.5rem 1.04rem;
        background-color: var(--colors-primary);
    }

    .iconHolder {
        cursor: pointer;
        margin-left: 1rem;
    }

    .pillSize__small {
        height: 2.8rem;
    }

    .pillSize__large {
        height: 4rem;
        padding-left: 1.8rem;
        padding-right: 1.8rem;
    }
}
@layer packages {
    .pillBaseStyle {
        display: inline-flex;
        align-items: center;
        border-radius: calc(var(--globals-baseUnit) * 2);
        padding: 0.5rem 0.9rem 0.5rem 1.04rem;
        background-color: var(--colors-primary);
    }

    .iconHolder {
        cursor: pointer;
        margin-left: 1rem;
    }

    .pillSize__small {
        height: 2.8rem;
    }

    .pillSize__large {
        height: 4rem;
        padding-left: 1.8rem;
        padding-right: 1.8rem;
    }
}
