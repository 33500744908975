@layer packages {
    .highlight {
        --highlight-color: var(--colors-primary);

        overflow: hidden;
        background-image: linear-gradient(var(--highlight-color), var(--highlight-color) 30%, transparent 30%);
        background-repeat: no-repeat;
        background-position: 0 0.8em;
        position: relative;
        font-weight: 700;

        animation: underline 1.5s linear;
    }
}

@keyframes underline {
    from {
        background-position: -100em 0.8em;
    }
    to {
        background-position: 0 0.8em;
    }
}
@layer packages {
    .highlight {
        --highlight-color: var(--colors-primary);

        overflow: hidden;
        background-image: linear-gradient(var(--highlight-color), var(--highlight-color) 30%, transparent 30%);
        background-repeat: no-repeat;
        background-position: 0 0.8em;
        position: relative;
        font-weight: 700;

        animation: underline 1.5s linear;
    }
}

@keyframes underline {
    from {
        background-position: -100em 0.8em;
    }
    to {
        background-position: 0 0.8em;
    }
}
