@layer packages {
     .modalHeaderWrapperStyle {
        display: flex;
        flex: 0 0 auto;
        align-items: center;
        padding: 0 0 0 calc(var(--globals-baseUnit) * 2);
     }

    .headerStyle {
        flex: 1;
    }
}
@layer packages {
     .modalHeaderWrapperStyle {
        display: flex;
        flex: 0 0 auto;
        align-items: center;
        padding: 0 0 0 calc(var(--globals-baseUnit) * 2);
     }

    .headerStyle {
        flex: 1;
    }
}
