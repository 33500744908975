@layer packages {
    .centerBox {
        display: flex;
        flex-grow: 1;
    }

    .centerBoxContent {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex: 1 1 0;
    }
}
@layer packages {
    .centerBox {
        display: flex;
        flex-grow: 1;
    }

    .centerBoxContent {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex: 1 1 0;
    }
}
