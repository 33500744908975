@layer packages {
    .wrapperStyle {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .contentWrapperStyle {
        --expandable-default-animation-time: 0;
        position: relative;
        width: 100%;
        overflow: hidden;
        transition: height var(--expandable-default-animation-time);
    }

    .shadowStyle {
        height: 60%;
        width: 100%;
        position: absolute;
        top: 40%;
        background: linear-gradient(0deg, #f9f9f9 0%, rgba(249, 249, 249, 0) 100%);
    }

    .buttonStyle {
        min-width: calc(var(--globals-baseUnit)*25);
        transform: translateY(-50%);
    }

    .buttonIconStyle {
        --expandable-default-animation-time: 0;
        margin-right: calc(var(--globals-baseUnit)*2);
        transition: transform var(--expandable-default-animation-time);
    }

    .buttonIconExpandedStyle {
        transform: scale(2.4) rotate(180deg);
    }

    .buttonIconCollapsedStyle {
        transform: scale(2.4) rotate(0);
    }

}
@layer packages {
    .wrapperStyle {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .contentWrapperStyle {
        --expandable-default-animation-time: 0;
        position: relative;
        width: 100%;
        overflow: hidden;
        transition: height var(--expandable-default-animation-time);
    }

    .shadowStyle {
        height: 60%;
        width: 100%;
        position: absolute;
        top: 40%;
        background: linear-gradient(0deg, #f9f9f9 0%, rgba(249, 249, 249, 0) 100%);
    }

    .buttonStyle {
        min-width: calc(var(--globals-baseUnit)*25);
        transform: translateY(-50%);
    }

    .buttonIconStyle {
        --expandable-default-animation-time: 0;
        margin-right: calc(var(--globals-baseUnit)*2);
        transition: transform var(--expandable-default-animation-time);
    }

    .buttonIconExpandedStyle {
        transform: scale(2.4) rotate(180deg);
    }

    .buttonIconCollapsedStyle {
        transform: scale(2.4) rotate(0);
    }

}
