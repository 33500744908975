@layer packages {
    .panelExpandableWrapper {
        width: 100%;
    };

    .panelExpandableWrapperMD {
        @media (min-width: 1024px) {
            border-bottom: none;
        }
    }

    .panelExpandableHeader {
        display: flex;
        justify-content: space-between;
        font-size: 1.2rem;
        padding: 1.8rem 0;

        @media (min-width: 1024px) {
            padding: 0 0 1.8rem 0;
        }
    }

    .panelExpandableIconWrapper {
        display: flex;
        align-items: center;
        margin-right: 1rem;
    }

    .panelExpandableIconWrapperMD {
        @media (min-width: 1024px) {
            display: none;
        }
    }

    .panelExpandableBody {
        --maxHeight: initial;

        font-size: 1.2rem;
        display: flex;
        padding: 0 1rem;
        flex-direction: column;
        transition: max-height 0.2s ease-in-out;
        max-height: var(--maxHeight);
    }

    .panelExpandableBodySM {
        @media (min-width: 768px) {
            flex-direction: row;
        }
    }

    .panelExpandableBodyMD {
        @media (min-width: 1024px) {
            padding: 0;
            max-height: initial;
            overflow: initial;
        }
    }
}
@layer packages {
    .panelExpandableWrapper {
        width: 100%;
    };

    .panelExpandableWrapperMD {
        @media (min-width: 1024px) {
            border-bottom: none;
        }
    }

    .panelExpandableHeader {
        display: flex;
        justify-content: space-between;
        font-size: 1.2rem;
        padding: 1.8rem 0;

        @media (min-width: 1024px) {
            padding: 0 0 1.8rem 0;
        }
    }

    .panelExpandableIconWrapper {
        display: flex;
        align-items: center;
        margin-right: 1rem;
    }

    .panelExpandableIconWrapperMD {
        @media (min-width: 1024px) {
            display: none;
        }
    }

    .panelExpandableBody {
        --maxHeight: initial;

        font-size: 1.2rem;
        display: flex;
        padding: 0 1rem;
        flex-direction: column;
        transition: max-height 0.2s ease-in-out;
        max-height: var(--maxHeight);
    }

    .panelExpandableBodySM {
        @media (min-width: 768px) {
            flex-direction: row;
        }
    }

    .panelExpandableBodyMD {
        @media (min-width: 1024px) {
            padding: 0;
            max-height: initial;
            overflow: initial;
        }
    }
}
