@layer packages {
    .loader {
        display: inline-flex;
        border-radius: 18rem;
        justify-content: center;
    }

    .loaderScaleToContainer {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        border-radius: 0;
        align-content: center;
        justify-content: center;
    }

    .loaderFullWidth {
        width: 100%;
    }

    .loaderPaddingSm {
        padding: calc(1 * var(--globals-baseUnit)) 0;
    }

    .loaderPaddingMd {
        padding: calc(1.5 * var(--globals-baseUnit)) 0;
    }

    .loaderPaddingLg {
        padding: calc(2 * var(--globals-baseUnit)) 0;
    }

    .loaderPaddingXLg {
        padding: calc(5 * var(--globals-baseUnit)) 0;
    }

    .loaderPaddingXXL {
        padding: calc(10 * var(--globals-baseUnit)) 0;
    }

    .loaderIconWrap {
        display: inline-flex;
    }

    .loaderIconWrapScaleToContainer {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 100%;
        width: auto;
    }

    .loaderIconWrapXSm {
        width: calc(2 * var(--globals-baseUnit));
        height: calc(2 * var(--globals-baseUnit));
    }

    .loaderIconWrapSm {
        width: calc(3 * var(--globals-baseUnit));
        height: calc(3 * var(--globals-baseUnit));
    }

    .loaderIconWrapMd {
        width: calc(4 * var(--globals-baseUnit));
        height: calc(4 * var(--globals-baseUnit));
    }

    .loaderIconWrapLg {
        width: calc(5 * var(--globals-baseUnit));
        height: calc(5 * var(--globals-baseUnit));
    }

    .loaderIconWrapXl {
        width: calc(12 * var(--globals-baseUnit));
        height: calc(12 * var(--globals-baseUnit));
    }
}
@layer packages {
    .loader {
        display: inline-flex;
        border-radius: 18rem;
        justify-content: center;
    }

    .loaderScaleToContainer {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        border-radius: 0;
        align-content: center;
        justify-content: center;
    }

    .loaderFullWidth {
        width: 100%;
    }

    .loaderPaddingSm {
        padding: calc(1 * var(--globals-baseUnit)) 0;
    }

    .loaderPaddingMd {
        padding: calc(1.5 * var(--globals-baseUnit)) 0;
    }

    .loaderPaddingLg {
        padding: calc(2 * var(--globals-baseUnit)) 0;
    }

    .loaderPaddingXLg {
        padding: calc(5 * var(--globals-baseUnit)) 0;
    }

    .loaderPaddingXXL {
        padding: calc(10 * var(--globals-baseUnit)) 0;
    }

    .loaderIconWrap {
        display: inline-flex;
    }

    .loaderIconWrapScaleToContainer {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 100%;
        width: auto;
    }

    .loaderIconWrapXSm {
        width: calc(2 * var(--globals-baseUnit));
        height: calc(2 * var(--globals-baseUnit));
    }

    .loaderIconWrapSm {
        width: calc(3 * var(--globals-baseUnit));
        height: calc(3 * var(--globals-baseUnit));
    }

    .loaderIconWrapMd {
        width: calc(4 * var(--globals-baseUnit));
        height: calc(4 * var(--globals-baseUnit));
    }

    .loaderIconWrapLg {
        width: calc(5 * var(--globals-baseUnit));
        height: calc(5 * var(--globals-baseUnit));
    }

    .loaderIconWrapXl {
        width: calc(12 * var(--globals-baseUnit));
        height: calc(12 * var(--globals-baseUnit));
    }
}
