@layer packages {
    .overlayStyle {
        position: fixed;
        inset: 0;
        background-color: rgb(from var(--colors-secondary) R G B / 0.75);
        display: flex;
    }

    .overlayStyleLight {
        background-color: rgb(from var(--colors-secondary) R G B / 0.35);
    }
}
@layer packages {
    .overlayStyle {
        position: fixed;
        inset: 0;
        background-color: rgb(from var(--colors-secondary) R G B / 0.75);
        display: flex;
    }

    .overlayStyleLight {
        background-color: rgb(from var(--colors-secondary) R G B / 0.35);
    }
}
