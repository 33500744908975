@layer packages {
    .xs {
        inset: 0;
        animation: fade-in 0.1s cubic-bezier(0.39, 0.575, 0.565, 1) both;

        @media (min-width: 768px) {
            inset: unset;
            animation: none;
            opacity: 1;
        }
    }

    .fullscreen {
        inset: 0;
        animation: fade-in 0.1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@layer packages {
    .xs {
        inset: 0;
        animation: fade-in 0.1s cubic-bezier(0.39, 0.575, 0.565, 1) both;

        @media (min-width: 768px) {
            inset: unset;
            animation: none;
            opacity: 1;
        }
    }

    .fullscreen {
        inset: 0;
        animation: fade-in 0.1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
