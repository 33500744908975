@layer packages {
    .imageWrapper {
        --error-width: auto;
        --error-height: auto;
        --ratio-xs: auto;
        aspect-ratio: var(--ratio-xs);

        position: relative;
        line-height: 0;
        display: inline-block;
        overflow: hidden;
        width: var(--error-width);
        height: var(--error-height);

        @media screen and (min-width: 1024px) {
            --ratio-md: var(--ratio-xs);
            aspect-ratio: var(--ratio-md);
        }
    }

    .imageWrapperBlock {
        display: block;
    }

    .imageWrapperError {
        max-width: 100%;
    }

    .imageWithRatio {
        --ratio-xs: auto;
        aspect-ratio: var(--ratio-xs);

        display: block;
        max-width: 100%;
        height: auto;
        /* object fit is added because this is how previous ratio hack positioned the image, ie. in OfferBox.
        Putting that here is not perfect, but makes package transition easier */
        object-fit: cover;
        object-position: top;

        @media screen and (min-width: 1024px) {
            --ratio-md: var(--ratio-xs);
            aspect-ratio: var(--ratio-md);
        }
    }
}
@layer packages {
    .imageWrapper {
        --error-width: auto;
        --error-height: auto;
        --ratio-xs: auto;
        aspect-ratio: var(--ratio-xs);

        position: relative;
        line-height: 0;
        display: inline-block;
        overflow: hidden;
        width: var(--error-width);
        height: var(--error-height);

        @media screen and (min-width: 1024px) {
            --ratio-md: var(--ratio-xs);
            aspect-ratio: var(--ratio-md);
        }
    }

    .imageWrapperBlock {
        display: block;
    }

    .imageWrapperError {
        max-width: 100%;
    }

    .imageWithRatio {
        --ratio-xs: auto;
        aspect-ratio: var(--ratio-xs);

        display: block;
        max-width: 100%;
        height: auto;
        /* object fit is added because this is how previous ratio hack positioned the image, ie. in OfferBox.
        Putting that here is not perfect, but makes package transition easier */
        object-fit: cover;
        object-position: top;

        @media screen and (min-width: 1024px) {
            --ratio-md: var(--ratio-xs);
            aspect-ratio: var(--ratio-md);
        }
    }
}
