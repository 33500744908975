@layer packages {
    .placeholderBase {
        background-color: var(--colors-gray-300);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    .placeholderIcon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(6);
        fill: var(--colors-gray-700);
        max-width: 100%;
    }
}
@layer packages {
    .placeholderBase {
        background-color: var(--colors-gray-300);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    .placeholderIcon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(6);
        fill: var(--colors-gray-700);
        max-width: 100%;
    }
}
