@layer packages {
    .base {
        padding: calc(var(--globals-baseUnit) * 2);
        align-self: flex-start;
        z-index: 1;
        cursor: pointer;
    }

    .left {
        position: absolute;
        top: 0;
        left: 0;
    }

    .right {
        position: absolute;
        top: 0;
        right: 0;
    }
}
@layer packages {
    .base {
        padding: calc(var(--globals-baseUnit) * 2);
        align-self: flex-start;
        z-index: 1;
        cursor: pointer;
    }

    .left {
        position: absolute;
        top: 0;
        left: 0;
    }

    .right {
        position: absolute;
        top: 0;
        right: 0;
    }
}
