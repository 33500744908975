@layer packages {
    .svgIconBase {
        --iconFill: black;
        --iconSize: 1;
        fill: var(--iconFill);
        transform: scale(var(--iconSize));
        width: 10px;
        height: 10px;
    }
}
@layer packages {
    .svgIconBase {
        --iconFill: black;
        --iconSize: 1;
        fill: var(--iconFill);
        transform: scale(var(--iconSize));
        width: 10px;
        height: 10px;
    }
}
