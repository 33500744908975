@layer packages {
    .wrapper {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        overflow-x: hidden;
        width: 100%;

        @media (min-width: 768px) {
            flex-direction: row;
        }
    }

    .modalCloseButtonStyle {
        position: absolute;
        inset: 1rem 1rem auto auto;
        cursor: pointer;
    }
}
@layer packages {
    .wrapper {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        overflow-x: hidden;
        width: 100%;

        @media (min-width: 768px) {
            flex-direction: row;
        }
    }

    .modalCloseButtonStyle {
        position: absolute;
        inset: 1rem 1rem auto auto;
        cursor: pointer;
    }
}
