@layer packages {
    .wrapperStyle {
        padding: 2rem 5rem 2rem 2rem;
        display: flex;
        flex-direction: column;
        gap: calc(var(--globals-baseUnit) * 2);
        overflow-y: auto;
        overflow-x: hidden;

        @media (min-width: 768px) {
            max-width: 50rem;
        }
    }

    .modalCloseButtonStyle {
        position: absolute;
        inset: var(--globals-baseUnit) var(--globals-baseUnit) auto auto;
        cursor: pointer;
    }

    .buttonsWrapperStyle {
        display: flex;
        flex-direction: column;
        gap: calc(var(--globals-baseUnit) * 2);

        @media (min-width: 768px) {
            flex-direction: row;
        }
    }

    .buttonStyle {
        width: 100%;

        @media (min-width: 768px) {
            width: 20rem;
        }
    }

}
@layer packages {
    .wrapperStyle {
        padding: 2rem 5rem 2rem 2rem;
        display: flex;
        flex-direction: column;
        gap: calc(var(--globals-baseUnit) * 2);
        overflow-y: auto;
        overflow-x: hidden;

        @media (min-width: 768px) {
            max-width: 50rem;
        }
    }

    .modalCloseButtonStyle {
        position: absolute;
        inset: var(--globals-baseUnit) var(--globals-baseUnit) auto auto;
        cursor: pointer;
    }

    .buttonsWrapperStyle {
        display: flex;
        flex-direction: column;
        gap: calc(var(--globals-baseUnit) * 2);

        @media (min-width: 768px) {
            flex-direction: row;
        }
    }

    .buttonStyle {
        width: 100%;

        @media (min-width: 768px) {
            width: 20rem;
        }
    }

}
