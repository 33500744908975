@layer packages {
    .svgIconWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
@layer packages {
    .svgIconWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
