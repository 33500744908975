@layer packages {
    .container {
        margin-right: auto;
        margin-left: auto;
        padding-left: var(--globals-baseUnit);
        padding-right: var(--globals-baseUnit);
        max-width: 360px;

        @media (min-width: 0px) {
            padding-left: var(--grid-sm-offsetPx);
            padding-right: var(--grid-sm-offsetPx);
            max-width: var(--grid-sm-widthPx);
        }

        @media (min-width: 1024px) {
            padding-left: var(--grid-md-offsetPx);
            padding-right: var(--grid-md-offsetPx);
            max-width: var(--grid-md-widthPx);
        }

        @media (min-width: 1366px) {
            padding-left: var(--grid-lg-offsetPx);
            padding-right: var(--grid-lg-offsetPx);
            max-width: var(--grid-lg-widthPx);
        }
    }

    .containerFluid {
        margin-right: auto;
        margin-left: auto;
        width: 100%;
    }
}
@layer packages {
    .container {
        margin-right: auto;
        margin-left: auto;
        padding-left: var(--globals-baseUnit);
        padding-right: var(--globals-baseUnit);
        max-width: 360px;

        @media (min-width: 0px) {
            padding-left: var(--grid-sm-offsetPx);
            padding-right: var(--grid-sm-offsetPx);
            max-width: var(--grid-sm-widthPx);
        }

        @media (min-width: 1024px) {
            padding-left: var(--grid-md-offsetPx);
            padding-right: var(--grid-md-offsetPx);
            max-width: var(--grid-md-widthPx);
        }

        @media (min-width: 1366px) {
            padding-left: var(--grid-lg-offsetPx);
            padding-right: var(--grid-lg-offsetPx);
            max-width: var(--grid-lg-widthPx);
        }
    }

    .containerFluid {
        margin-right: auto;
        margin-left: auto;
        width: 100%;
    }
}
