@layer packages {
    .contentElement {
        position: absolute;
        background-color: #ffffff;
        overflow: auto;
        outline: none;
        padding: 0;
        max-height: 100vh;
        display: flex;
        flex-direction: column;
    }

    .borderRadius {
        --border-radius: 2;
        --border-radius-md: var(--border-radius);

        border-radius: calc(var(--border-radius) * var(--globals-baseUnit));

        @media (min-width: 1023px) {
            border-radius: calc(var(--border-radius-md) * var(--globals-baseUnit));
        }
    }
}
@layer packages {
    .contentElement {
        position: absolute;
        background-color: #ffffff;
        overflow: auto;
        outline: none;
        padding: 0;
        max-height: 100vh;
        display: flex;
        flex-direction: column;
    }

    .borderRadius {
        --border-radius: 2;
        --border-radius-md: var(--border-radius);

        border-radius: calc(var(--border-radius) * var(--globals-baseUnit));

        @media (min-width: 1023px) {
            border-radius: calc(var(--border-radius-md) * var(--globals-baseUnit));
        }
    }
}
