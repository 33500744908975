@layer packages {

    .componentContainerNotReadOnlyStyle {
        display: inline-flex;
        align-items: center;

        &:hover svg.ds-radio-frame {
            fill: black;
        }
    }

    .componentContainerReadOnlyStyle {
        display: inline-flex;
        align-items: center;
    }

    .labelContainerStyle {
        cursor: pointer;
        margin-left: 0.8rem;
    }

    .radioWrapperStyle {
        position: relative;
        cursor: pointer;
    }

    .hiddenInputStyle {
        cursor: inherit;
        position: absolute;
        opacity: 0;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        margin: 0;
        padding: 0;
        z-index: 20;

        & ~ div {
            transition: outline, var(--transition-timingFunction), var(--transition-duration);
            outline: 0.2rem solid transparent;
            border-radius: 4rem;
        }

        &:focus-visible ~ div {
            outline: 0.2rem solid var(--colors-info);
            }
    }

    .radioWrapStyle {
        border-radius: 0.4rem;
    }

    .radioContainerStyle {
        width: 2.4rem;
        height: 2.4rem;
    }

    .radioFrameStyle {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
    }

    .radioFrameSvgStyle {
        width: 100%;
        height: 100%;
        transition: fill, var(--transition-timingFunction), var(--transition-duration);
    }

    .radioActiveMarkStyle {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 11;
        transition: opacity, var(--transition-timingFunction), var(--transition-duration);
    }

    .radioActiveMarkSvgStyle {
        width: 100%;
        height: 100%;
        transition: fill, var(--transition-timingFunction), var(--transition-duration);

    }
}
@layer packages {

    .componentContainerNotReadOnlyStyle {
        display: inline-flex;
        align-items: center;

        &:hover svg.ds-radio-frame {
            fill: black;
        }
    }

    .componentContainerReadOnlyStyle {
        display: inline-flex;
        align-items: center;
    }

    .labelContainerStyle {
        cursor: pointer;
        margin-left: 0.8rem;
    }

    .radioWrapperStyle {
        position: relative;
        cursor: pointer;
    }

    .hiddenInputStyle {
        cursor: inherit;
        position: absolute;
        opacity: 0;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        margin: 0;
        padding: 0;
        z-index: 20;

        & ~ div {
            transition: outline, var(--transition-timingFunction), var(--transition-duration);
            outline: 0.2rem solid transparent;
            border-radius: 4rem;
        }

        &:focus-visible ~ div {
            outline: 0.2rem solid var(--colors-info);
            }
    }

    .radioWrapStyle {
        border-radius: 0.4rem;
    }

    .radioContainerStyle {
        width: 2.4rem;
        height: 2.4rem;
    }

    .radioFrameStyle {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
    }

    .radioFrameSvgStyle {
        width: 100%;
        height: 100%;
        transition: fill, var(--transition-timingFunction), var(--transition-duration);
    }

    .radioActiveMarkStyle {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 11;
        transition: opacity, var(--transition-timingFunction), var(--transition-duration);
    }

    .radioActiveMarkSvgStyle {
        width: 100%;
        height: 100%;
        transition: fill, var(--transition-timingFunction), var(--transition-duration);

    }
}
