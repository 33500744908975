@layer packages {
    .textBase {
        --text-local-align: left;
        --text-local-color: inherit;
        --mb-local: 0;

        color: var(--text-local-color);
        text-align: var(--text-local-align);
        margin: 0 0 var(--mb-local);
    }

    .textBasePrimary {
        font-family: var(--text-family-primary), "Poppins", sans-serif;
    }

    .textBaseSecondary {
        font-family: var(--text-family-secondary), "Playfair_Display", serif;
    }

    .noWrapStyle {
        white-space: nowrap;
    }

    .HEADLINE_0 {
        font-weight: 600;
        font-size: 3.079rem;
        line-height: 4rem;

        @media screen and (min-width: 1024px) {
            font-size: 3.592rem;
            line-height: 5.2rem;
        }
    }

    .HEADLINE_1 {
        font-weight: 700;
        font-size: 2.737rem;
        line-height: 3.6rem;

        @media screen and (min-width: 1024px) {
            font-size: 3.193rem;
            line-height: 4.4rem;
        }
    }

    .HEADLINE_2 {
        font-weight: 700;
        font-size: 2.433rem;
        line-height: 3.2rem;

        @media screen and (min-width: 1024px) {
            font-size: 2.838rem;
            line-height: 3.6rem;
        }
    }

    .HEADLINE_3 {
        font-weight: 500;
        font-size: 2.162rem;
        line-height: 2.8rem;

        @media screen and (min-width: 1024px) {
            font-size: 2.523rem;
            line-height: 3.2rem;
        }
    }

    .HEADLINE_4 {
        font-weight: 500;
        font-size: 1.922rem;
        line-height: 2.4rem;

        @media screen and (min-width: 1024px) {
            font-size: 2.243rem;
            line-height: 2.8rem;
        }
    }

    .HEADLINE_5 {
        font-weight: 500;
        font-size: 1.709rem;
        line-height: 2.4rem;

        @media screen and (min-width: 1024px) {
            font-size: 1.993rem;
            line-height: 2.4rem;
        }
    }

    .HEADLINE_6 {
        font-weight: 500;
        font-size: 1.519rem;
        line-height: 2rem;

        @media screen and (min-width: 1024px) {
            font-size: 1.772rem;
            line-height: 2.4rem;
        }
    }

    .BODY_COPY_0 {
        font-weight: 400;
        font-size: 1.519rem;
        line-height: 2.4rem;

        @media screen and (min-width: 1024px) {
            font-size: 1.772rem;
            line-height: 2.8rem;
        }
    }

    .BODY_COPY_1 {
        font-weight: 400;
        font-size: 1.35rem;
        line-height: 2rem;

        @media screen and (min-width: 1024px) {
            font-size: 1.575rem;
            line-height: 2.4rem;
        }
    }

    .BODY_COPY_2 {
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 2rem;

        @media screen and (min-width: 1024px) {
            font-size: 1.4rem;
        }
    }

    .INFO_TXT_1 {
        font-weight: 400;
        font-size: 1.067rem;
        line-height: 1.2rem;

        @media screen and (min-width: 1024px) {
            font-size: 1.244rem;
            line-height: 1.6rem;
        }
    }

    .INFO_TXT_2 {
        font-weight: 400;
        font-size: 0.948rem;
        line-height: 1.2rem;

        @media screen and (min-width: 1024px) {
            font-size: 1.106rem;
        }
    }

    .INFO_TXT_3 {
        font-weight: 400;
        font-size: 0.948rem;
        line-height: 1.2rem;

        @media screen and (min-width: 1024px) {
            font-size: 0.983rem;
        }
    }
    .BUTTON_BIG {
        font-weight: 600;
        font-size: 1.772rem;
        line-height: 2.4rem;

        text-transform: uppercase;
        letter-spacing: 0.02em;
    }

    .BUTTON_MEDIUM {
        font-weight: 600;
        font-size: 1.575rem;
        line-height: 2.4rem;

        text-transform: uppercase;
        letter-spacing: 0.02em;
    }

    .BUTTON_SMALL {
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 2rem;

        text-transform: uppercase;
        letter-spacing: 0.02em;
    }

    .BUTTON_MICRO {
        font-weight: 600;
        font-size: 1.244rem;
        line-height: 2.4rem;

        text-transform: uppercase;
        letter-spacing: 0.02em;
    }

    .MINI_HEADER {
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 2rem;

        text-transform: uppercase;
        letter-spacing: 0.15em;

        @media screen and (min-width: 1024px) {
            font-size: 1.2rem;
        }
    }

    .strongStyle {
        font-weight: 700;
    }
}

@layer packages {
    .textBase {
        --text-local-align: left;
        --text-local-color: inherit;
        --mb-local: 0;

        color: var(--text-local-color);
        text-align: var(--text-local-align);
        margin: 0 0 var(--mb-local);
    }

    .textBasePrimary {
        font-family: var(--text-family-primary), "Poppins", sans-serif;
    }

    .textBaseSecondary {
        font-family: var(--text-family-secondary), "Playfair_Display", serif;
    }

    .noWrapStyle {
        white-space: nowrap;
    }

    .HEADLINE_0 {
        font-weight: 600;
        font-size: 3.079rem;
        line-height: 4rem;

        @media screen and (min-width: 1024px) {
            font-size: 3.592rem;
            line-height: 5.2rem;
        }
    }

    .HEADLINE_1 {
        font-weight: 700;
        font-size: 2.737rem;
        line-height: 3.6rem;

        @media screen and (min-width: 1024px) {
            font-size: 3.193rem;
            line-height: 4.4rem;
        }
    }

    .HEADLINE_2 {
        font-weight: 700;
        font-size: 2.433rem;
        line-height: 3.2rem;

        @media screen and (min-width: 1024px) {
            font-size: 2.838rem;
            line-height: 3.6rem;
        }
    }

    .HEADLINE_3 {
        font-weight: 500;
        font-size: 2.162rem;
        line-height: 2.8rem;

        @media screen and (min-width: 1024px) {
            font-size: 2.523rem;
            line-height: 3.2rem;
        }
    }

    .HEADLINE_4 {
        font-weight: 500;
        font-size: 1.922rem;
        line-height: 2.4rem;

        @media screen and (min-width: 1024px) {
            font-size: 2.243rem;
            line-height: 2.8rem;
        }
    }

    .HEADLINE_5 {
        font-weight: 500;
        font-size: 1.709rem;
        line-height: 2.4rem;

        @media screen and (min-width: 1024px) {
            font-size: 1.993rem;
            line-height: 2.4rem;
        }
    }

    .HEADLINE_6 {
        font-weight: 500;
        font-size: 1.519rem;
        line-height: 2rem;

        @media screen and (min-width: 1024px) {
            font-size: 1.772rem;
            line-height: 2.4rem;
        }
    }

    .BODY_COPY_0 {
        font-weight: 400;
        font-size: 1.519rem;
        line-height: 2.4rem;

        @media screen and (min-width: 1024px) {
            font-size: 1.772rem;
            line-height: 2.8rem;
        }
    }

    .BODY_COPY_1 {
        font-weight: 400;
        font-size: 1.35rem;
        line-height: 2rem;

        @media screen and (min-width: 1024px) {
            font-size: 1.575rem;
            line-height: 2.4rem;
        }
    }

    .BODY_COPY_2 {
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 2rem;

        @media screen and (min-width: 1024px) {
            font-size: 1.4rem;
        }
    }

    .INFO_TXT_1 {
        font-weight: 400;
        font-size: 1.067rem;
        line-height: 1.2rem;

        @media screen and (min-width: 1024px) {
            font-size: 1.244rem;
            line-height: 1.6rem;
        }
    }

    .INFO_TXT_2 {
        font-weight: 400;
        font-size: 0.948rem;
        line-height: 1.2rem;

        @media screen and (min-width: 1024px) {
            font-size: 1.106rem;
        }
    }

    .INFO_TXT_3 {
        font-weight: 400;
        font-size: 0.948rem;
        line-height: 1.2rem;

        @media screen and (min-width: 1024px) {
            font-size: 0.983rem;
        }
    }
    .BUTTON_BIG {
        font-weight: 600;
        font-size: 1.772rem;
        line-height: 2.4rem;

        text-transform: uppercase;
        letter-spacing: 0.02em;
    }

    .BUTTON_MEDIUM {
        font-weight: 600;
        font-size: 1.575rem;
        line-height: 2.4rem;

        text-transform: uppercase;
        letter-spacing: 0.02em;
    }

    .BUTTON_SMALL {
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 2rem;

        text-transform: uppercase;
        letter-spacing: 0.02em;
    }

    .BUTTON_MICRO {
        font-weight: 600;
        font-size: 1.244rem;
        line-height: 2.4rem;

        text-transform: uppercase;
        letter-spacing: 0.02em;
    }

    .MINI_HEADER {
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 2rem;

        text-transform: uppercase;
        letter-spacing: 0.15em;

        @media screen and (min-width: 1024px) {
            font-size: 1.2rem;
        }
    }

    .strongStyle {
        font-weight: 700;
    }
}

