@layer packages {
    .badgeBaseStyle {
        border-radius: 1.6rem;
        display: inline-flex;
        align-items: center;
        padding: 0.5rem 0.9rem 0.5rem 1.04rem;
    }

    .labelStyle {
        margin-right: 1.04rem;
    }
}
@layer packages {
    .badgeBaseStyle {
        border-radius: 1.6rem;
        display: inline-flex;
        align-items: center;
        padding: 0.5rem 0.9rem 0.5rem 1.04rem;
    }

    .labelStyle {
        margin-right: 1.04rem;
    }
}
