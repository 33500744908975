@layer packages {
    .pictureBase {
        display: block;
        position: relative;
    }

    .hasErrorModule {
        width: 100%;
    }

    .pictureSize {
        --aspect-ratio: auto;
        --width: auto;
        --height: auto;

        aspect-ratio: var(--aspect-ratio);
        height: var(--height);
        width: var(--width);
    }

    .pictureFit {
        height: 100%;
    }
}
@layer packages {
    .pictureBase {
        display: block;
        position: relative;
    }

    .hasErrorModule {
        width: 100%;
    }

    .pictureSize {
        --aspect-ratio: auto;
        --width: auto;
        --height: auto;

        aspect-ratio: var(--aspect-ratio);
        height: var(--height);
        width: var(--width);
    }

    .pictureFit {
        height: 100%;
    }
}
