@layer packages {
    .image {
        display: block;
        max-width: 100%;
        width: auto;
        height: auto;
    }

    .imageFit {
        --object-position: 50% 50%;
        object-position: var(--object-position);
        object-fit: cover;
        height: 100%;
        width: 100%;
    }

    .hasErrorModule {
        display: none;
    }
}
@layer packages {
    .image {
        display: block;
        max-width: 100%;
        width: auto;
        height: auto;
    }

    .imageFit {
        --object-position: 50% 50%;
        object-position: var(--object-position);
        object-fit: cover;
        height: 100%;
        width: 100%;
    }

    .hasErrorModule {
        display: none;
    }
}
