@layer packages {
    .wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 1 1 100%;
        height: 100%;
        overflow: auto;
        padding: calc(var(--globals-baseUnit) * 2);
    }
}
@layer packages {
    .wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 1 1 100%;
        height: 100%;
        overflow: auto;
        padding: calc(var(--globals-baseUnit) * 2);
    }
}
