@layer packages {
    .linkStyle {
        cursor: pointer;

        .linkStyle:hover {
            color: var(--highlight-color);
        }

        .linkStyle:active {
            color: var(--colors-gray-700);
        }
    }

    .underlineStyle, .underlineStyle:hover, .underlineStyle:active {
        text-decoration: underline;
    }
}

@layer packages {
    .linkStyle {
        cursor: pointer;

        .linkStyle:hover {
            color: var(--highlight-color);
        }

        .linkStyle:active {
            color: var(--colors-gray-700);
        }
    }

    .underlineStyle, .underlineStyle:hover, .underlineStyle:active {
        text-decoration: underline;
    }
}

