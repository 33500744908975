@layer packages {
    .fieldMessageWrapper {
        --field-state-color: var(--colors-gray-600);

        color: var(--field-state-color);
        text-align: right;
        margin-top: calc(0.5 * var(--globals-baseUnit));
    }
}
@layer packages {
    .fieldMessageWrapper {
        --field-state-color: var(--colors-gray-600);

        color: var(--field-state-color);
        text-align: right;
        margin-top: calc(0.5 * var(--globals-baseUnit));
    }
}
