.buttonBannerPrimaryText {
    font-weight: 600;
}

.buttonMapTooltipText {
    font-size: 1.106rem;
    line-height: 1.2rem;
    font-weight: 700;
}

.buttonHighlightedText {
    padding: 0 calc(0.5 * var(--globals-baseUnit));
    text-wrap: wrap;
}
.buttonBannerPrimaryText {
    font-weight: 600;
}

.buttonMapTooltipText {
    font-size: 1.106rem;
    line-height: 1.2rem;
    font-weight: 700;
}

.buttonHighlightedText {
    padding: 0 calc(0.5 * var(--globals-baseUnit));
    text-wrap: wrap;
}
