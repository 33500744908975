@layer packages {
    /*Shared*/

    ._transitionStyle {
        opacity: 1;
        transition-property: box-shadow, color, background-color, opacity, fill;
        transition-duration: 0.15s;
        transition-timing-function: ease-in-out;
    }

    ._brandIcon {
        background-color: transparent;
        color: var(--colors-secondary);
        fill: var(--colors-secondary);
        border-color: transparent;
    }

    /*Component*/
    .button {
        /*prevent Safari from showing rounded corners and gradient backgrounds.*/
        -webkit-appearance: none !important;
        overflow: hidden;

        position: relative;
        display: inline-flex;
        margin-bottom: 0;
        outline-color: var(--colors-info);

        composes: _transitionStyle;

        justify-content: center;
        white-space: nowrap;
        align-items: center;
        touch-action: manipulation;
        cursor: pointer;
        border-width: 1px;
        border-style: solid;
        height: 5.6rem;
        user-select: none;
        padding: calc(2 * var(--globals-baseUnit));
        border-radius: 18rem;

        &:hover {
            filter: saturate(0.75);
        }

        &:disabled {
            cursor: not-allowed;
        }
    }

    .buttonCustomTag {
        text-align: center;
    }

    .buttonFilledPrimary {
        background-color: var(--colors-primary);
        color: var(--button-primaryLabelColor);
        fill: var(--colors-secondary);
        border-color: var(--colors-primary);
        min-width: calc(12 * var(--globals-baseUnit));

        &:hover {
            box-shadow: var(--shadow-1);
        }

        &:disabled {
            background-color: var(--colors-background-100);
            border-color: transparent;
            color: var(--colors-gray-700);
            fill: var(--colors-gray-700);
            box-shadow: var(--shadow-0);
        }
    }

    .buttonFilledSecondary {
        background-color: var(--colors-secondary);
        color: white;
        fill: white;
        border-color: var(--colors-secondary);
        min-width: calc(12 * var(--globals-baseUnit));

        &:hover {
            box-shadow: var(--shadow-1);
        }

        &:disabled {
            background-color: var(--colors-gray-700);
            border-color: var(--colors-gray-700);
            color: var(--colors-gray-300);
            fill: var(--colors-gray-300);
            box-shadow: var(--shadow-0);
        }
    }

    .buttonOutlinedSecondary {
        background-color: transparent;
        color: var(--colors-secondary);
        fill: var(--colors-secondary);
        border-color: var(--colors-secondary);
        min-width: calc(12 * var(--globals-baseUnit));

        &:hover {
            background-color: var(--colors-primary);
        }

        &:disabled {
            background-color: transparent;
            border-color: var(--colors-gray-700);
            color: var(--colors-gray-700);
            fill: var(--colors-gray-700);
        }
    }

    .buttonOutlinedSecondaryLight {
        background-color: transparent;
        color: white;
        fill: white;
        border-color: white;
        min-width: calc(12 * var(--globals-baseUnit));

        &:hover {
            background-color: var(--colors-primary);
            color: var(--colors-secondary);
            fill: var(--colors-secondary);
            border-color: var(--colors-secondary);
        }

        &:hover path {
            fill: var(--colors-secondary);
        }

        &:disabled {
            background-color: transparent;
            border-color: var(--colors-gray-700);
            color: var(--colors-gray-700);
            fill: var(--colors-gray-700);
        }

        &:disabled path {
            fill: var(--colors-gray-700);
        }
    }

    .buttonNoneSecondary {
        background-color: transparent;
        color: var(--colors-secondary);
        fill: var(--colors-secondary);
        border-color: transparent;

        &:hover {
            color: var(--colors-highlight);
            fill: var(--colors-highlight);
        }

        &:disabled {
            background-color: transparent;
            border-color: var(--colors-gray-700);
            color: var(--colors-gray-700);
            fill: var(--colors-gray-700);
        }
    }

    .buttonBrandIconMedium {
        composes: _brandIcon;
        height: calc(6 * var(--globals-baseUnit));
    }

    .buttonBrandIconBig {
        composes: _brandIcon;
        height: calc(8 * var(--globals-baseUnit));
    }

    .buttonBrandIconLeftIcon {
        margin-right: 0.8rem;
    }

    .buttonBrandIconRightIcon {
        margin-left: 0.8rem;
    }

    .buttonStretchedPrimary {
        background-color: var(--colors-primary);
        color: var(--colors-secondary);
        fill: var(--colors-secondary);
        border-color: var(--colors-primary);
        border-radius: 0;
        height: calc(8 * var(--globals-baseUnit));
        padding: calc(2.5 * var(--globals-baseUnit));
        width: 100%;

        &:disabled {
            background-color: transparent;
            border-color: transparent;
            color: var(--colors-gray-700);
            fill: var(--colors-gray-700);
        }
    }

    .buttonStretchedPrimaryIcon {
        position: absolute;
    }

    .buttonStretchedPrimaryLeftIcon {
        left: 2.2rem;
    }

    .buttonStretchedPrimaryRightIcon {
        right: 2.2rem;
    }

    .buttonHighlightPrimary {
        color: var(--colors-secondary);
        fill: var(--colors-secondary);
        border-color: transparent;
        background-color: transparent;
        height: unset;
        padding: 0 ;
        min-width: 6rem;
        overflow: hidden;
        position: relative;

        &:disabled {
            background-color: transparent;
            border-color: transparent;
            color: var(--colors-gray-700);
            fill: var(--colors-gray-700);

            &:before {
                content: none;
            }
        }
    }

    .buttonMapTooltip {
        color: white;
        fill: white;
        border-color: var(--colors-gray-700);
        background-color: var(--colors-gray-700);
        height: calc(3 * var(--globals-baseUnit));
        min-width: calc(8 * var(--globals-baseUnit));
        z-index: 2;
        border-radius: var(--globals-baseUnit);
        padding: 1.6rem 0.96rem;

        &:disabled {
            background-color: transparent;
            border-color: transparent;
            color: var(--colors-gray-700);
            fill: var(--colors-gray-700);

            &:before {
                content: none;
            }
        }
    }

    .buttonMapTooltipLeftIcon {
        margin-right: 1.06rem;
    }

    .buttonMapTooltipRightIcon {
        margin-left: 1.06rem;
    }

    .buttonBannerPrimary {
        background-color: var(--colors-highlight);
        color: var(--button-primaryLabelColor);
        fill: var(--colors-secondary);
        border-color: var(--colors-highlight);
        min-width: 9.6rem;

        &:hover {
            box-shadow: var(--shadow-1);
            color: var(--button-primaryLabelColor);
        }

        &:disabled {
            background-color: var(--colors-background-100);
            border-color: transparent;
            color: var(--colors-gray-700);
            fill: var(--colors-gray-700);
            box-shadow: var(--shadow-0);
        }
    }

    .buttonDefault {
        background-color: var(--colors-primary);
        color: var(--colors-secondary);
        fill: var(--colors-secondary);
        border-color: var(--border-color);

        &:hover {
            box-shadow: var(--shadow-1);
        }

        &:disabled {
            background-color: transparent;
            border-color: transparent;
            color: var(--colors-gray-700);
            fill: var(--colors-gray-700);
            box-shadow: var(--shadow-0);
        }
    }

    .buttonLeftIcon {
        margin-right: 0.8rem;
    }

    .buttonRightIcon {
        margin-left: 0.8rem;
    }

    .buttonContentWrapper {
        composes: _transitionStyle;
    }

    .buttonContentWrapperIcon {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    /*Sizes are NOT for: brand-icon, stretched-primary, highlight-primary, map-tool-tip*/
    .buttonBig {
        height: calc(9 * var(--globals-baseUnit));
    }

    .buttonMedium {
        height: calc(7 * var(--globals-baseUnit));
    }

    .buttonSmall {
        height: calc(6 * var(--globals-baseUnit));
    }

    .buttonXSmall {
        height: calc(5 * var(--globals-baseUnit));
    }

    .buttonFullWidth {
        width: 100%;
    }
}
@layer packages {
    /*Shared*/

    ._transitionStyle {
        opacity: 1;
        transition-property: box-shadow, color, background-color, opacity, fill;
        transition-duration: 0.15s;
        transition-timing-function: ease-in-out;
    }

    ._brandIcon {
        background-color: transparent;
        color: var(--colors-secondary);
        fill: var(--colors-secondary);
        border-color: transparent;
    }

    /*Component*/
    .button {
        /*prevent Safari from showing rounded corners and gradient backgrounds.*/
        -webkit-appearance: none !important;
        overflow: hidden;

        position: relative;
        display: inline-flex;
        margin-bottom: 0;
        outline-color: var(--colors-info);

        composes: _transitionStyle;

        justify-content: center;
        white-space: nowrap;
        align-items: center;
        touch-action: manipulation;
        cursor: pointer;
        border-width: 1px;
        border-style: solid;
        height: 5.6rem;
        user-select: none;
        padding: calc(2 * var(--globals-baseUnit));
        border-radius: 18rem;

        &:hover {
            filter: saturate(0.75);
        }

        &:disabled {
            cursor: not-allowed;
        }
    }

    .buttonCustomTag {
        text-align: center;
    }

    .buttonFilledPrimary {
        background-color: var(--colors-primary);
        color: var(--button-primaryLabelColor);
        fill: var(--colors-secondary);
        border-color: var(--colors-primary);
        min-width: calc(12 * var(--globals-baseUnit));

        &:hover {
            box-shadow: var(--shadow-1);
        }

        &:disabled {
            background-color: var(--colors-background-100);
            border-color: transparent;
            color: var(--colors-gray-700);
            fill: var(--colors-gray-700);
            box-shadow: var(--shadow-0);
        }
    }

    .buttonFilledSecondary {
        background-color: var(--colors-secondary);
        color: white;
        fill: white;
        border-color: var(--colors-secondary);
        min-width: calc(12 * var(--globals-baseUnit));

        &:hover {
            box-shadow: var(--shadow-1);
        }

        &:disabled {
            background-color: var(--colors-gray-700);
            border-color: var(--colors-gray-700);
            color: var(--colors-gray-300);
            fill: var(--colors-gray-300);
            box-shadow: var(--shadow-0);
        }
    }

    .buttonOutlinedSecondary {
        background-color: transparent;
        color: var(--colors-secondary);
        fill: var(--colors-secondary);
        border-color: var(--colors-secondary);
        min-width: calc(12 * var(--globals-baseUnit));

        &:hover {
            background-color: var(--colors-primary);
        }

        &:disabled {
            background-color: transparent;
            border-color: var(--colors-gray-700);
            color: var(--colors-gray-700);
            fill: var(--colors-gray-700);
        }
    }

    .buttonOutlinedSecondaryLight {
        background-color: transparent;
        color: white;
        fill: white;
        border-color: white;
        min-width: calc(12 * var(--globals-baseUnit));

        &:hover {
            background-color: var(--colors-primary);
            color: var(--colors-secondary);
            fill: var(--colors-secondary);
            border-color: var(--colors-secondary);
        }

        &:hover path {
            fill: var(--colors-secondary);
        }

        &:disabled {
            background-color: transparent;
            border-color: var(--colors-gray-700);
            color: var(--colors-gray-700);
            fill: var(--colors-gray-700);
        }

        &:disabled path {
            fill: var(--colors-gray-700);
        }
    }

    .buttonNoneSecondary {
        background-color: transparent;
        color: var(--colors-secondary);
        fill: var(--colors-secondary);
        border-color: transparent;

        &:hover {
            color: var(--colors-highlight);
            fill: var(--colors-highlight);
        }

        &:disabled {
            background-color: transparent;
            border-color: var(--colors-gray-700);
            color: var(--colors-gray-700);
            fill: var(--colors-gray-700);
        }
    }

    .buttonBrandIconMedium {
        composes: _brandIcon;
        height: calc(6 * var(--globals-baseUnit));
    }

    .buttonBrandIconBig {
        composes: _brandIcon;
        height: calc(8 * var(--globals-baseUnit));
    }

    .buttonBrandIconLeftIcon {
        margin-right: 0.8rem;
    }

    .buttonBrandIconRightIcon {
        margin-left: 0.8rem;
    }

    .buttonStretchedPrimary {
        background-color: var(--colors-primary);
        color: var(--colors-secondary);
        fill: var(--colors-secondary);
        border-color: var(--colors-primary);
        border-radius: 0;
        height: calc(8 * var(--globals-baseUnit));
        padding: calc(2.5 * var(--globals-baseUnit));
        width: 100%;

        &:disabled {
            background-color: transparent;
            border-color: transparent;
            color: var(--colors-gray-700);
            fill: var(--colors-gray-700);
        }
    }

    .buttonStretchedPrimaryIcon {
        position: absolute;
    }

    .buttonStretchedPrimaryLeftIcon {
        left: 2.2rem;
    }

    .buttonStretchedPrimaryRightIcon {
        right: 2.2rem;
    }

    .buttonHighlightPrimary {
        color: var(--colors-secondary);
        fill: var(--colors-secondary);
        border-color: transparent;
        background-color: transparent;
        height: unset;
        padding: 0 ;
        min-width: 6rem;
        overflow: hidden;
        position: relative;

        &:disabled {
            background-color: transparent;
            border-color: transparent;
            color: var(--colors-gray-700);
            fill: var(--colors-gray-700);

            &:before {
                content: none;
            }
        }
    }

    .buttonMapTooltip {
        color: white;
        fill: white;
        border-color: var(--colors-gray-700);
        background-color: var(--colors-gray-700);
        height: calc(3 * var(--globals-baseUnit));
        min-width: calc(8 * var(--globals-baseUnit));
        z-index: 2;
        border-radius: var(--globals-baseUnit);
        padding: 1.6rem 0.96rem;

        &:disabled {
            background-color: transparent;
            border-color: transparent;
            color: var(--colors-gray-700);
            fill: var(--colors-gray-700);

            &:before {
                content: none;
            }
        }
    }

    .buttonMapTooltipLeftIcon {
        margin-right: 1.06rem;
    }

    .buttonMapTooltipRightIcon {
        margin-left: 1.06rem;
    }

    .buttonBannerPrimary {
        background-color: var(--colors-highlight);
        color: var(--button-primaryLabelColor);
        fill: var(--colors-secondary);
        border-color: var(--colors-highlight);
        min-width: 9.6rem;

        &:hover {
            box-shadow: var(--shadow-1);
            color: var(--button-primaryLabelColor);
        }

        &:disabled {
            background-color: var(--colors-background-100);
            border-color: transparent;
            color: var(--colors-gray-700);
            fill: var(--colors-gray-700);
            box-shadow: var(--shadow-0);
        }
    }

    .buttonDefault {
        background-color: var(--colors-primary);
        color: var(--colors-secondary);
        fill: var(--colors-secondary);
        border-color: var(--border-color);

        &:hover {
            box-shadow: var(--shadow-1);
        }

        &:disabled {
            background-color: transparent;
            border-color: transparent;
            color: var(--colors-gray-700);
            fill: var(--colors-gray-700);
            box-shadow: var(--shadow-0);
        }
    }

    .buttonLeftIcon {
        margin-right: 0.8rem;
    }

    .buttonRightIcon {
        margin-left: 0.8rem;
    }

    .buttonContentWrapper {
        composes: _transitionStyle;
    }

    .buttonContentWrapperIcon {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    /*Sizes are NOT for: brand-icon, stretched-primary, highlight-primary, map-tool-tip*/
    .buttonBig {
        height: calc(9 * var(--globals-baseUnit));
    }

    .buttonMedium {
        height: calc(7 * var(--globals-baseUnit));
    }

    .buttonSmall {
        height: calc(6 * var(--globals-baseUnit));
    }

    .buttonXSmall {
        height: calc(5 * var(--globals-baseUnit));
    }

    .buttonFullWidth {
        width: 100%;
    }
}
