@layer packages {
    .center {
        justify-content: center;
    }

    .left {
        justify-content: flex-start;
    }

    .right {
        justify-content: flex-end;
    }
}
@layer packages {
    .center {
        justify-content: center;
    }

    .left {
        justify-content: flex-start;
    }

    .right {
        justify-content: flex-end;
    }
}
